<template>
    <div class="review-order">
        <!-- <van-popup v-model="showReview"> -->
            <div class="concat-content">
                <div class="top">
                    <div class="title">{{editingContact.workTitle}}</div>
                    <p class="tips">
                        <span>发布人:</span>
                        <span>{{editingContact.creater}}</span>
                        <span>{{editingContact.orderTime}}</span>
                    </p>
                    <p class="work-content">
                        <span>工作内容:</span>
                        <span>{{editingContact.workTitle}}</span>
                    </p>
                    <p class="work-park">
                        <span>园区地块:</span>
                        <span>{{editingContact.massifName}}</span>
                    </p>
                </div>
                <van-form @submit="submitData()">
                    <!-- <van-field
                        v-model="editingContact.workTitle"
                        name="workTitle"
                        label="工作标题"
                        readonly 
                    /> -->
                    <div class="img-label">上传图片({{num}}/5)</div>
                    <div class="img-container" v-if="isReview">
                        <van-image v-if="editingContact.imgUrl1" width="80" height="80" :src="editingContact.imgUrl1"/>
                        <van-image v-if="editingContact.imgUrl2" width="80" height="80" :src="editingContact.imgUrl2"/>
                        <van-image v-if="editingContact.imgUrl3" width="80" height="80" :src="editingContact.imgUrl3"/>
                        <van-image v-if="editingContact.imgUrl4" width="80" height="80" :src="editingContact.imgUrl4"/>
                        <van-image v-if="editingContact.imgUrl5" width="80" height="80" :src="editingContact.imgUrl5"/>
                    </div>

                    <div class="img-container" v-else>
                        <van-uploader v-model="fileList" accept="image/*" :max-size="5 * 1024 * 1024" @oversize="onOversize"
                            :before-read="beforeRead" :before-delete="beforeDelete"
                        />
                    </div>
                    <van-field
                        v-model="editingContact.remark"
                        label="备注"
                        class="title-label"
                        required
                    /> 
                    <van-field
                        v-model="editingContact.remark"
                        name="remark"
                        rows="5"
                        label=""
                        class="context-input"
                        type="textarea"
                        :readonly="isReview"
                        placeholder="请填写备注"
                        :rules="[{ required: true}]"
                    />
                    <div class="form-bottom">
                        <van-button round block type="info"  native-type="submit">提交</van-button>
                    </div>
                </van-form>
                <!-- <div class="form-bottom" v-if="isReview">
                    <van-button round block type="default" @click="submitData(false)">不通过</van-button>
                    <van-button round block type="info" @click="submitData(true)">通过</van-button>
                </div>
                <div class="form-bottom" v-else>
                    <van-button round block type="default" @click="showReview=false">取消</van-button>
                    <van-button round block type="info" @click="submitData()">提交</van-button>
                </div> -->
                
            </div>
        <!-- </van-popup> -->
    </div>
</template>

<script>
import axios from 'axios';
import {getToken} from '@/utils/cookie';
import {
    listPageOrder,
    addOrder,
    modifyOrder,
    deleteOrder,
    getCurrentUser
} from '@/api/workOrder';
export default {
    data() {
        return {
            editingContact: {
                workTitle: '',
                creater: '',
                orderTime: '',
                workTitle: '',
                massifName: '',
                imgUrl1: '',
                imgUrl2: '',
                imgUrl3: '',
                imgUrl4: '',
                imgUrl5: '',
                remark: '',
                auditStatus: 0,
                urlArr: '',
                orderStatus: '',

            },
            num: 0,
            fileList: [],
            allUrl: [],
            isReview: false,
        }
    },
    methods:{
        /** 文件上传限制 */
        onOversize() {
            this.$notify({ type: 'warning', message: '文件大小不能超过 5M' });
        },
        /** 文件上传前 */
        beforeRead(file) {
            /* if (file.type != 'image/jpeg' && file.type != 'image/png') {
                this.$notify({ type: 'warning', message: '请上传jpg/png格式图片' });
                return false;
            } */
            if (this.fileList.length >= 5) {
                this.$notify({ type: 'warning', message: '最多上传5张图片' });
                return false;
            }
            let formData = new FormData(); // 为上传文件定义一个formData对象
            let config = {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'token': getToken()
                }
            };
            formData.append('file', file)
            axios.post('http://172.26.202.35:8100/nj/file/upload', formData, config).then(res => {
                if (res.data.code == 200) {
                    this.allUrl.push(res.data.data[0]);
                    this.num = this.allUrl.length;
                }
            });
            return true;
        },
        /** 删除图片 */
        beforeDelete(file, obj) {
            this.allUrl.forEach((item, index) => {
                if (index == obj.index) {
                    this.allUrl.splice(index, 1);
                    this.num = this.allUrl.length;
                    return false;
                }
            });
            return true
        },
        /** 通过 */
        submitData(flag) {
            delete this.editingContact.urlArr;
            if (this.isReview) {
                if (flag) {
                    this.editingContact.auditStatus = 1;
                    this.editingContact.orderStatus = 3;
                } else {
                    this.editingContact.auditStatus = 2;
                    this.editingContact.orderStatus = 1;
                }
            } else {
                for (let i = 0; i < 5; i ++) {
                    let url = 'imgUrl' + (i + 1);
                    if (this.allUrl && this.allUrl[i]) {
                        this.editingContact[url] = this.allUrl[i];   
                    } else {
                        this.editingContact[url] = '';
                    } 
                }
                this.editingContact.orderStatus = 2;
            }
            // this.$emit('submitData', this.editingContact, flag)
            this.modifyOrder(this.editingContact)
        },
        /** 提交 */
        modifyOrder(val) {
            modifyOrder(val).then(res => {
                if (res.code == 200) {
                    /* if (flag ) {
                        this.$notify({ type: 'success', message: '审核成功' });
                    } else {
                        this.$notify({ type: 'success', message: '提交成功' });
                    } */
                    this.$notify({ type: 'success', message: '提交成功' });
                    this.$router.push({name: 'workOrder'})
                }
            });
        }
    },
    beforeRouteEnter(to, from, next) {
        next(vm => {
            vm.editingContact = to.params;
        });
    }
}
</script>

<style lang="scss">
.review-order {
    height: 100%;
    background-color: #fff;
    .concat-content {
        width: 345px;
        padding: 10px;
        .top {
            margin-bottom: 18px;
            .title {
                margin-bottom: 8px;
                font-size: 18px;
                color: #4F4F4F;
            }
            .tips {
                margin-bottom: 16px;
                font-size: 12px;
                color: #A3A3A3;
            }
            .work-content,
            .work-park {
                margin-bottom: 4px;
                font-size: 14px;
                color: #505050;
                span:nth-of-type(1) {
                    margin-right: 8px;
                }
            }
        }
        .img-label {
            margin-bottom: 14px;
            font-size: 14px;
            color: #333;
        }
        .img-container {
            margin-top: 8px;
            .van-image {
                margin-right: 4px;
            }
        }
        .form-bottom {
            position: fixed;
            bottom: 20px;
            left: 14px;
            .van-button--block {
                display: inline-block;
                width: 347px;
                height: 43px;
                background-color: #4499FF;
            }
            .van-button--round {
                border-radius: 4px;
            }
        }
    }
    .van-uploader__preview {
        margin-right: 6px;
    }
    .van-uploader__preview-image {
        width: 90px;
        height: 90px;
    }
    .van-uploader__upload {
        width: 90px;
        height: 90px;
    }
    .context-input {
        margin-top: 10px;
        // box-shadow: 0px 0px 8px 0px rgba(205,205,205,0.5);
        border-radius: 4px;  
        border: 1px solid #979797;
        background: #fff;
    }
    .title-label {
        font-size: 16px;
        font-weight: 500;
        color: #000;
        margin-top: 5px;
        .van-cell__value  {
            display: none;
        }
    }
    .van-cell::after {
        border: 0;
    }
}
</style>